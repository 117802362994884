.side-row h6,
.side-row label {
  color: #333;
}

.side-nav {
  width: 340px;
  flex-basis: 20%;
  border: 1px solid gray;
  border-radius: var(--border-radius-1);
}

.side-row {
  border-bottom: 1px solid var(--color-grey);
  padding: 0.4rem 1.5rem;
}

.side-row label {
  font-size: 0.8rem !important;
  font-weight: 300;
}

.side-row h6 {
  font-size: 1rem;
  margin: 1px;
}


@media screen and (max-width: 720px) {
  .side-nav {
    display: none;
  }
}
