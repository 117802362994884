.hero-mid {
  /* background: linear-gradient(rgba(255, 0, 0, 0.363), rgba(0, 0, 255, 0.247)), url('../../../public/assets/banner.jpg'); */
  width: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)), url("../../../public/assets/Banner_Recap2022.avif");
  background-position: center;
  background-size: cover;
}

.about-banner .hero-banner {
  background-color: var(--color-theme-light);
}
.banner.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(53, 53, 53, 0.534), rgba(0, 0, 0, 0.342));

}
.hero-full {
  height: 450px;
}

.product-banner {
  position: relative;
}

.hero-mid {
  height: 350px;
}
.bg-lucent{
  background: rgba(10, 10, 10, 0.425);
}

.card-img {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.hero-mid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .hero-full {
    height: 270px;
  }
}
