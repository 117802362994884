@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.prompt-thin {
  font-family: "Prompt", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.prompt-extralight {
  font-family: "Prompt", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.prompt-light {
  font-family: "Prompt", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.prompt-regular {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.prompt-medium {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.prompt-semibold {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.prompt-bold {
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.prompt-extrabold {
  font-family: "Prompt", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.prompt-black {
  font-family: "Prompt", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.prompt-thin-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.prompt-extralight-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.prompt-light-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.prompt-regular-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.prompt-medium-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.prompt-semibold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.prompt-bold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.prompt-extrabold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.prompt-black-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 900;
  font-style: italic;
}



@font-face {
  font-family: spantara;
  src: url("./assets/fonts/spantaran.ttf");
  unicode-range: U+0-2f, U+40-10FFFF;
}

@font-face {
  font-family: spantara;
  src: local("Franklin Gothic Medium");
  unicode-range: U+30-39;
}

* {
  box-sizing: border-box;
  font-family:  "poppins", Prompt, Helvetica, sans-serif;
  text-decoration: none;
  margin: 0;
  padding: 0;
  appearance: none;

}

:root {
  --color-main-orange: rgb(256, 68, 4);
  --color-main-bg: #135092;
  --color-contrast: rgb(256, 68, 4);
  --color-danger: #ff7782;
  --color-success: #0a8a5f;
  --color-warning: #fb5;
  --color-orange: #ff4500;
  --color-theme: rgba(255, 69, 0, 1);
  --color-theme-variant: #143850;
  --color-theme-light: rgba(20, 56, 80, 0.774);

  --color-primary: #081d2b;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-info-light: #dce1eb;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-primary-variant: #135092;
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;

  --bg-green: #04aa6d;
  --bg-gray-light: rgba(132, 139, 200, 0.18);
  --bg-gray-dark: #363949;
  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radium-3: 1.2rem;
  --card-padding: 1.8rem;
  --padding-1: 1.2rem;
  --box-shadow: 0 2rem 3rem var(--color-light);
}

body {
  font-size: 14px;
  background: var(--color-background);
  color: rgb(20, 56, 80);
}

.text-green {
  color: green;
}


hr{
  height: 1px;
  border: 1px solid rgb(186, 88, 88);
}

.image-slider-img{
transition: translate 0.3s ease-in-out;
}
.image-slider{
  width: 100%;
  height: 100%;
}
.image-slider-btn{
  /* all: unset; */
  display: block;
  top: 0;
  bottom: 0;
  background-color: rgba(219, 219, 219, 0.103);
  padding: 10px ;
  opacity: 0.2;

}
.image-slider-btn:hover{
  background: #33333328;
  opacity: 0.3;

}
.image-slider-btn > *{
  color: black;
}

.imagePrevModal{
  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;

}

.imagePrevModal.show{
  opacity: 1;
  pointer-events: auto;

}
.thumbnail-div{
  order: 1;
  -ms-flex-order: 1;
}
.thumbnail{
  width: 50px;
  height: 50px;
}
.border-l{
  border-left: 1px solid rgb(221, 221, 221);
}

.text-gray {
  color: var(--color-dark-variant);
}
.text-gray-dark {
  color: #333;
}
.text-orange {
  color: var(--color-main-orange);
}

:root {
  --bg-primary-color: #222629;
  --color-grey: rgb(27, 27, 27);
  --color-white: #fff;
  --color-light-gray: rgb(112, 112, 112);
}

.seperator {
  border-bottom: 1px solid rgb(235, 83, 83);
  padding-bottom: 2rem;
  margin-top: 2rem;
}


.spantara {
  font-family: spantara;
}

.line-space {
  letter-spacing: 0.1rem;
}

h3,
h4,
h2 {
  font-weight: 600;
  font-family: spantara, 'Courier New', Courier, monospace;
}

.c-form-row label{
  width: 20%;
}
.c-form-row input, .c-form-row select{
  width: 75%;
}

.headers {
  letter-spacing: 1rem;
}



a {
  text-decoration: none;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 0;
}
.right-mid{
  right: 7px;
  top: 50%;
  /* transform: translateY(0%); */
}


.white {
  color: white;
}

.overlay h3 {
  font-size: 29px;
}
.service-prod .item:hover .overlay{
  height: 100%;

}
.feature .card .overlay{

  padding: 0rem 2rem;
}
.feature .card:hover .overlay{
  height: 50%;
  padding: 2rem;
  
}
.feature .card .info-fix {
  height: 50px;
  
}
.feature .card:hover .info-fix {
  height: 0px;
  
}
.feature .card{
  
}
.overlay {
  height: 0%;
  overflow: hidden;
  left: 0;
  width: 100%;
  bottom: 0;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  transition: 0.4s all ease;
}

.chat-box {
  position: fixed;
  width: 100%;
  max-width: 600px;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 99;
  cursor: pointer;

  background: white;
  padding: 1.5rem;
  transition: all 0.73s ease-in-out;
}

.chat-icon {
  position: fixed;
  right: 0;
  top: 70%;
  z-index: 8;
  padding: 1.5rem;
}

.chat-icon .stick-out {
  font-size: 4rem;
  color: #135092;
  box-shadow: 0 0 2px rgb(112, 112, 112);
  border-radius: 50%;
  background-color: rgb(228, 77, 23);
  opacity: 0.4;
}

.chat-icon .stick-out:hover {
  opacity: 1;
}

.box-shadow {
  box-shadow: 0 0 2px rgba(112, 112, 112, 0.4);
}

.chat-box.show {
  right: -100%;
}

.chat-box form {
  position: relative;
  padding: 12px;
  top: 35%;
  transform: translateY(-50%);
}

.chat-box .side-close {
  font-size: 3rem;
}

.chat-box .side-close:hover {
  color: var(--color-orange);
}

.chat-box form input {
  padding: 19px;
}

.full-100,
.full-length {
  width: 100%;
}

.warning-center {
  display: block;
  margin-top: 130px;
  font-size: 38px;
  text-align: center;
}

.line-space {
  word-spacing: 0.4rem;
}

.p-container,
.contact-us,
.about-us {
  max-width: 1500px;
  width: 100%;
  margin: 20px auto;
}

.border-theme{
  border: 1px solid var(--color-info-dark);;
}
.about-us {
  border: 2px solid rgba(256, 68, 4, 0.46);
  border-radius: 1rem;
  margin: 0.8rem auto;
}

.about-us ul li {
  list-style: disc;
}


.bg-red {
  background-color: red;
}

.segment {
  overflow: hidden;
  position: relative;
  background-position: center;
  background-size: auto;
  border-bottom: 3px solid rgb(91, 91, 91);
  position: relative;
  background-color: rgba(52, 52, 52, 0.342);
}

.segment-one {
  background-image: linear-gradient(rgba(19, 80, 144, 0.3), rgba(0, 0, 4, 0.4)), url('../public/assets/6277667d13a5d6b2a15f8ce3d8f87c8a.jpg');
}

.segment-one p {
  padding: 0.8rem;
  background-color: #36394970;
}

.segment::after {
  content: "";
  background: linear-gradient(rgba(0, 225, 225, 0.027), rgba(0, 0, 0, 0.1));
  width: 0%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  position: absolute;
}

.segment:hover::after {
  width: 100%;
}

.products-container,
.section-container {
  padding: 1rem;
  max-width: 1400px;
  margin: auto;
}

.products-container h3 {
  font-size: 1.245rem;
}

.zoom-hover:hover{
  transition: all 0.3s ease-in-out;
  transform: scale(1.03);
}
.products-container h2,
.products-container h3 {
  font-weight: 100;

  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
}

.products-container .p-img {
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
  background-position: center;
}

.product-containers .p-img img {
  height: 100%;
  object-fit: contain;
}



.prod-page {
  padding: 0.5rem;
  max-width: 1500px;
  margin: auto;
}


.fc-white {
  color: #fff;
}

.fs-3 {
  font-size: 1.5rem;
}


.w-350{
  width: 350px;
}
.h-350{
  height: 350px;
}
.w-080{
  width: 80%;
}
.h-350{
  height: 350px;
}
.w-100{
  width: 100px;
}
.w-50{
  width: 50px;
}

.contact-us form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact-us input,
.contact-us textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  resize: none;
}

/* ==========================flex-box======================== */

.w-max-400 {
  max-width: 400%;
}

.w-max-890 {
  max-width: 890px;
  background-color: red;
}




.bg-green {
  background-color: green;
}


.feature-div {
  width: 85%;
}

button {
  color: #333;
  width: fit-content;
}

.prod-page .cat-group a {
  font-size: 0.8rem;
  font-weight: 600;
  margin-right: 0.2rem;
  padding: 0.2rem 0.4rem;
  background-color: var(--color-theme-variant);
  color: var(--color-info-light);
}
.characters {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.characters-col {
  display: grid;
  grid-template-columns: 1fr;
}

.characters .xter {
  font-weight: bold;
}

.catalog .cat-display ul {
  border-bottom: 1px solid gray;
}

.btn {
  border-radius: 4px;
  font-size: 1rem;
  @apply bg-theme-alt;
  cursor: pointer;
  color: var(--color-white);
}
@media (max-width: 480px) {
  .btn.contact{
    width: 100%;
  }  
}

form .btn {
  margin-top: 24px;
}

.btn:hover {
  background: var(--color-theme-light);
  color: var(--color-white);
}

.btn:active,
button:active {
  background-color: white;
  color: #222629;
  border: 1px solid #222629;
}

.cat-group {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  margin-bottom: 1rem;
}

.cat-group .btn {
  padding: 0.3rem 0.8rem;
  font-size: 0.8rem;
}


.color-grey {
  color: #333;
}

.max-width {
  width: 100%;
  display: block;
}

.bg-blue {
  background-color: blue;
}
.text-blue{
  color: blue;
}


.red {
  color: red;
}

/* ==============================Home====================== */
.product-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fit-screen {
  max-width: 700px;
  width: 80%;
  margin: auto;
}

.full-screen {
  max-width: 1800px;
  width: 100%;
  margin: 3rem auto;
}

.product-grid-items {
  background-color: #fff;
  width: 250px;
  height: 200px;
  border: none;
  box-shadow: 0 0 0.35rem 1px rgb(85 85 85 / 25%);
  padding: 5px 5px 15px;
  text-align: left;
  transition: all 0.3s ease;
}

.product-grid-items:hover {
  box-shadow: none;
}

.banner-text {
  background: linear-gradient(#13509218, white);
}

/* ====================product details design=================== */

.product-details {
  padding: 1.5rem;
  font-size: 0.8rem;
  color: #333;
}


table {
  width: 100%;
  border-collapse: collapse;
  user-select: none;
}


table tbody tr:last-child {
  border-bottom: 1px solid #222629;
}

table th {

  padding: 12px 16px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
table td {
  padding: 12px 16px;
    border: 1px solid rgb(208, 208, 208);
}



.div-center-flex {
  justify-content: center;
  align-items: center;
}

.product-display-image img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}


.product-display-image {
  width: 100%;
  max-width: 650px;
  height: 750px;
  margin: 10px;
}


.text-pry {
  color: var(--color-primary-variant) !important;
}

.text-orange {
  color: var(--color-orange);
}

.bg-pry {
  background: var(--color-primary-variant) !important;
}

.custom-bg-theme {
  background: linear-gradient(#13509218, white);
}


.whatsapp {
  padding-top: 4px;
  padding-bottom: 0;
  display: block;
  width: 188px;
  margin-left: auto;
  margin-right: 2rem;
  border-radius: 8px;
}

.whatsapp a {
  display: block;
  padding: 0;

}

.whatsapp img {
  height: 100%;
  width: 100%;
}

.series-btn {
  padding: 8px 10px;
  display: block;
  border-radius: 6px;
  background-color: rgba(256, 68, 4, 0.9);
}

.bg-gray {
  background-color: gray;
}

.carter {
  width: 50%;
}

.btn-div {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  width: max-content;
}

.btn-div span {
  padding-left: 6px;
  padding-right: 6px;
  margin: 0;
  height: 100%;
}


/* ===================== carts======================== */



.cart-count {
  color: #333;
}


.cart-side .total {
  border-top: 1px solid black;
}

.cart-side a {
  font-weight: 600;
  width: 100%;
  display: block;
  padding: 6px 0;
  text-align: center;
}

.cart-side a:hover {
  background: var(--bg-gray-dark);
}

.cart-btn-div {
  align-items: center;
  border: 1px solid #bdbdbd;
  border-radius: 6px;

margin-top: 10px;}



td .btn.change {
  font-size: 21px;
  padding: 10px;
  background: transparent;
}



.cart-div table td:last-child .btn {
  background: var(--color-danger);
  font-weight: 600;
  padding: 10px 6px;
  margin: auto;
}



.cart-div .cart-det p {
  color: #222629;
}

.service-offer {
  margin: 1rem auto;
  width: 100%;
  max-width: 1600px;
}

.service-offer img {
  width: 100%;
}

.cart-div .cart-det {
  flex-basis: 40%;
  border: 1px solid black;
}

.border-white {
  border: 1px solid var(--color-info-light);
}

.cart-div .cart-btn {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 6px;
}

.cart-div .cart-btn button {
  flex-basis: 25%;
}

.cart-div .clear {
  text-align: right;
  padding-right: 20px;
}

.cart-div .clear .btn {
  margin-top: 14px;
  padding: 7px 12px;
  background-color: var(--color-warning);
}

.modal-container {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  height: 100vh;
  @apply z-50;

}

.modal {
  width: 700px;
  margin: 20% auto;
  z-index: 99;
  text-align: center;
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
}

.modal .btn-container {
  margin: auto;
  width: 500px;
  display: flex;
  justify-content: space-around;
  gap: 4%;
}
.dash-container{
  padding: 10px;
  border-radius: 8px;
  max-width: 1100px;
  margin:  34px auto;
  /* border: 1px solid gray; */
  box-shadow: 0 0 2px 0 rgb(112, 112, 112);
}
.ml-auto{
  margin-left: auto;
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user span,
.user a {
  margin: 0 12px;
  cursor: pointer;
}

.user .user-icon {
  font-size: 1.6rem;
}


.checkout .col-right span {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--color-dark-variant);
}

.checkout .col-right ul li {
  padding: 15px 8px;
}

.service-table {
  position: relative;
}


.border-white {
  border: 1px solid white;
}

.close-btn .close.btn {
  background-color: #04aa6d;
  margin-bottom: 8px;
}

.close-btn .close.btn:hover {
  background-color: #ff4500;
}

.service-table table thead th {
  padding: 1.4rem 1rem;
}

.service-table table td {
  padding: 1.4rem 1rem;
  font-size: 14px;
}

.product-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #081d2b;
}

.product-overlay.none {
  display: none;
}

.product-overlay.show {
  display: flex;
}

.close-btn {
  margin-top: auto;
  text-align: center;
}

.overflow-hidden {
  overflow: hidden;
}
@media screen and (max-width: 1800px) {

  .xl-grid-col-4{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .xl-grid-col-3{
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1400px) {
  table {
    /* display: block; */
    overflow-x: auto;
    white-space: nowrap;
  }

  .cart-div {
    width: 90%;
    margin-top: 20px;
  }

  .lg-grid-col-4{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .lg-grid-col-3{
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .sm-grid-col-2{
    grid-template-columns: 1fr 1fr;
  }
  .cart-div {
    width: 100%;
    flex-direction: column;
  }
  .cart-div td:first-child{
   padding-top: 2rem;
  
  }
  .cart-div td:nth-child(odd) {
    content: attr(data-cell) ": ";
    font-weight: 700;
    text-transform: capitalize;
  
  }
  .cart-div td:nth-of-type() {
    content: attr(data-cell) ": ";
    font-weight: 700;
    text-transform: capitalize;
  
  }
  /* .cart-div td:nth-child() {
    content: attr(data-cell) ": ";
    font-weight: 700;
    text-transform: capitalize;
  
  } */
  .cart-div td::before{
    content: attr(data-cell) ": ";
    font-weight: 700;
    text-transform: capitalize;
  
  }
  .sm.px-2{
    padding: 0;
  }

  .whatsapp {
    margin: 1rem 0;
  }

  .products-container .p-img {
    width: 100%;
  }

  .products-container .p-img img {
    object-fit: contain;
  }

  .service-table.flex {
    flex-direction: column;
  }

  .service-table .technical,
  .service-table .description {
    font-size: 18px;
    padding: 0.6rem;
  }

   .cat-group .btn {
    font-size: 0.8rem;
  }

 
  .flex.row {
    flex-direction: row;
  }

  .cart-inner-div {
    width: 100%;
    overflow-x: auto;
    flex: 4;

  }

  @media screen and (max-width: 640px) {
    .flex-center-around {
      flex-direction: column;
    }
 
    .sm-flex-col, .sm-row-col {
      flex-direction: column;

    }
    .image-card{
      -ms-flex-order: 1;

    }
    .thumbnail-div{
      flex-direction: row !important;
      gap: 3px;
      order: 2;
   
    }

    .catalog .row {
      flex-direction: column;
    }

    .fs-3 {
      font-size: 1rem;
    }

    .cart-div {
      width: 100%;
    }

    .product-grid-items {
      flex: 1;
    }
    .to-top{
    order: 1;
    }
  }
}


::selection{
  background: #04aa6d68;
  color: white;
}
::-webkit-scrollbar{
  width: 10px;
  background: #1350926c;
  
}
::-webkit-scrollbar-thumb{
  background: var(--color-orange);
  border-radius: 10px;
  cursor: pointer;
}

html{
  scroll-behavior: smooth;
}