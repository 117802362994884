

.product-container {
  min-height: 70vh;
}


.product-details h3 {
  line-height: 2rem;
}

.product-details p {
  font-size: 0.88rem;
}

.product-align {
  flex: 1;
}

.product-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2%;
}
@media (max-width: 768px) {
  .product-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2%;
  }
}

.product-items > div {
  width: 100%;
}

.prod-img {
  margin: 0;
}




.Xteristic {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2%;
}

.Xteristic > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  border-bottom: 1px solid rgba(175, 174, 174, 0.514);
}

.Xteristic > div .col-6 {
  flex-basis: 50%;
}

.products-display img {
  width: 100%;
  height: 250px;
}

.prod-details {
  margin: 0;
}

.products-display h5 {
  margin: 0;
  font-size: 1rem;
}

.products-display p {
  margin: 0.5rem;
  color: #333;
}

@media screen and (max-width: 1200px) {
  .products-display {
    flex-basis: 24%;
  }
}

@media screen and (max-width: 920px) {
  .products-display {
    flex-basis: 30%;
  }
}

@media screen and (max-width: 720px) {
  .products-display {
    flex-basis: 25%;
  }
}

@media screen and (max-width: 600px) {
  .products-display {
    flex-basis: 49%;
    border-radius: 4px;
  }

  .Xteristic {
    grid-template-columns: 1fr;
  }
}
