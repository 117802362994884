.close {
  color: black;
}

.dark-theme-variable {
  --color-backgroun: #181a1e;
  --color-white: #202528;
  --color-dark: #edeffd;
  --color-dark-variant: #a3bdcc;
  --color-light: rgba(0, 0, 0, 0.4);
  --box-shadow: 0 2rem 3rem var(var(--color-light));
}
body {
  overflow-x: hidden;
  font-size: 0.88rem;
  width: 100vw;
  height: 100vh;
  color: var(--color-dark);
}


.admin-container * {
  font-family: poppins, sans-serif;
}
.no-scroll::-webkit-scrollbar{
  display: none;

}


.admin-img {
  display: block;
  width: 100%;
}

.h1 {
  font-weight: 800;
  font-size: 1.8rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 0.87rem;
}

h4 {
  font-size: 0.8rem;
}

h5 {
  font-size: 0.77rem;
}

small {
  font-size: 0.75rem;
}

.profile-photo {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;
}

.profile-photo img {
  width: 100%;
}
p {
  color: var(--color-dark-variant);
}

b {
  color: var(--color-dark);
}

.primary {
  color: var(--color-primary);
}

.danger {
  color: var(--color-danger);
}

.success {
  color: var(--color-success);
  /* color: */
}

.warning {
  color: var(--color-warning);
}



.bolder {
  text-align: center;
  margin-top: 180px;
}

aside {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

aside .top .logo a {
  
  margin-top: 1.4rem;
}

.product-form label {
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
}

.product-form form .form-row {
  display: flex;
  justify-content: space-between;
  gap: 1%;
}
.text-form-container {
  width: 100%;
}

textarea {
  width: 100%;
  padding: 1rem;
  border-radius: var(--border-radius-1);
  border: 1px solid rgb(204, 204, 204)
}

form input,
select {
  width: 100%;
  padding: 8px;
  font-size: 0.88rem;  border: 1px solid rgb(204, 204, 204);
  outline: #111e88;
  border-radius: var(--border-radius-1);
}

form input:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
}

/* form > div {
  margin: 8px 0;
} */

.admin form button {
  font-weight: 600;
  font-size: 1rem;
  padding: 8px;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: var(--border-radius-2);
}

.admin form trix-toolbar button {
background: white;
width: max-content;
border-radius: 0px;
}
/* trix-toolbar */

aside .top .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
}

aside .close {
  display: none;
}

aside .log img {
  width: 2rem;
  height: 2rem;
}


aside .side-bar a {
  /* display: flex; */
  color: var(--color-info-dark);
  gap: 1rem;
  align-items: center;
  position: relative;
  height: 3.7rem;
  transition: all 300ms ease;
}


aside .side-bar a span {
  font-size: 1.6rem;
  transition: all 300ms ease;
}

aside .side-bar a:last-child {
  bottom: 0;
  width: 100%;
}

aside .side-bar a.active {
  background-color: var(--color-light);
  color: var(--color-primary);
}

aside .side-bar a.active::before {
  content: "";
  width: 6px;
  height: 100%;
  background-color: var(--color-primary);
}

aside .side-bar a.active span {
  color: var(--color-primary);
  margin-left: calc(1rem - 3px);
}

aside .side-bar a:hover {
  color: var(--color-primary);
}

aside .side-bar a:hover span {
  margin-left: 1rem;
}

aside .side-bar .message-count {
  background: var(--color-danger);
  color: var(--color-white);
  padding: 2px 10px;
  font-size: 11px;
  border-radius: var(--border-radius-1);
}

main {
  width: 100%;
}

.category-modal {
  margin: 100px auto;
  width: 100%;
}

.category-div .close {
  text-align: right;
  width: 100%;
}

.category-div form {
  width: 100%;
  margin: auto;
}

.category-div .close-icon {
  font-size: 29px;
  font-weight: bolder;
}

.category-div {
  width: 80%;
  background: white;
  padding: 20px;
  margin: auto;
  z-index: 5;
}

.edit-display {
  display: none;
}

.cat-div {
  margin: 29px 0;
}

main h3,
main h1 {
  color: #363949;
}

main .date {
  display: inline-block;
  background: var(--color-light);
  border-radius: var(--border-radius-1);
  margin-top: 1rem;
  padding: 0.5rem 0.6rem;
}

main .date input[type="date"] {
  background: transparent;
  color: var(--color-dark);
}

main .insight > div {
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}

main .insight > div:hover {
  box-shadow: none;
}

main .insight > div .icon-chart {
  background-color: var(--color-primary-variant);
  padding: 0.5rem;
  border-radius: 50%;
  color: var(--color-white);
  font-size: 3rem;
}

main .insight > div.expenses span {
  background: var(--color-danger);
}

main .insight > div.income span {
  background: var(--color-success);
}


main .insight h3 {
  margin: 1rem 0 0.6rem;
  font-size: 1rem;
}

main .insight .progresser {
  /* width: 92px; */
  /* height: 92px; */
  border-radius: 50%;
}

main .insight svg circle {
  fill: none;
  stroke: orangered;
  stroke-width: 14;
  stroke-linecap: round;
  transform: translate(5px, 5px);
  /* stroke-dasharray: 110;
  stroke-dashoffset: 92; */
}

main .insight .sales svg circle {
  stroke-dasharray: 210;
  stroke-dashoffset: -30;
}

main .insight .expenses svg circle {
  stroke-dasharray: 80;
  stroke-dashoffset: 20;
}

main .insight .income svg circle {
  stroke-dasharray: 110;
  stroke-dashoffset: 35;
}

main table tbody td {
  border-bottom: 1px solid var(--color-light);
  color: var(--color-dark-variant);
}



.right .theme-toggler span.active {
  background: var(--color-primary);
  color: white;
  border-radius: var(--border-radius-1);
}


/* =======================recent update ===================== */
.right .recent-updates {
  margin-top: 1rem;
}

.iconStyle {
  font-weight: 700;
  font-size: 2rem;
}

/* ====================SAALE ANALYTIICS========================= */
.right .sales-analytics {
  margin-top: 2rem;
}

.right .sales-analytics .item {
 
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}

.right .sales-analytics .item:hover {
  box-shadow: none;
}

.right .sales-analytics .item .icon {
  padding: 0.6rem;
  color: var(--color-white);
  border-radius: 50%;
  background-color: red;
  display: flex;
}

.right .sales-analytics .item.offline .icon {
  background: var(--color-danger);
}

.right .sales-analytics .item.customers .icon {
  background: var(--color-success);
}

.right .sales-analytics .add-product {
  background-color: transparent;
  border: 2px dashed var(--color-primary);
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.right .sales-analytics .add-product div {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.right .sales-analytics .add-product h3 {
  font-weight: 600;
}

/* -------------------END OF ASIDE -------------------------- */

/* =====================MEDIA QUERIES ================================ */
@media screen and (max-width: 1280px) {
  .admin-container {
    padding-right: 20px;
    grid-template-columns: 5rem auto 16rem;
  }
  aside .side-bar a {
    display: flex;
    color: var(--color-info-dark);
    gap: 1rem;
    align-items: center;
    position: relative;
    height: 3.7rem;
    transition: all 300ms ease;
  }
  aside .side-bar a.active span {
    margin-left: 0;
  }
  
  
  aside .logo h2 {
    display: none;
  }

  aside .side-bar h3 {
    display: none;
  }

  aside .side-bar a:last-child {
    position: relative;
    margin-top: 1.8rem;
  }



  main table thead tr th:last-child,
  main table thead tr th:first-child {
    display: none;
  }

  main table tbody tr td:last-child,
  main table tbody tr td:first-child {
    display: none;
  }
  aside .side-bar .message-count {
    display: none;
  }
  aside .top .logo {
    display: none;
   
}
}

@media screen and (max-width: 1108px) {
  .admin-container {
    /* width: 94%; */
    grid-template-columns: 4rem auto 13rem;
  }
}

@media screen and (max-width: 768px) {
  .admin-container {
    width: 100%;
    grid-template-columns: 1fr;
  }

  aside {
    position: fixed;
    left: -100%;
    top: 0;
    background: var(--color-white);
    width: 18rem;
    z-index: 3;
    box-shadow: 1rem 3rem 4rem var(--color-light);
    height: 100vh;
    padding-right: var(--card-padding);
    transition: all 0.3s ease-in-out;
  }

  aside.display {
    left: 0;
  }

  aside .logo {
    margin-left: 1rem;
  }

  aside .side-bar h3 {
    display: inline;
  }

  aside .side-bar h2 {
    display: inline;
  }

  aside .side-bar a {
    width: 100%;
    height: 3.4rem;
  }

  aside .side-bar a:last-child {
    /* position: absolute; */
    /* bottom: 5rem; */
  }

  aside .close {
    display: inline-block;
    cursor: pointer;
  }

  .right .profile .info {
    display: none;
  }


  .right .top button span {
    font-size: 2rem;
  }
}
