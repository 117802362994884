.wallpaper {
  width: 100%;
  min-height: 100vh;
  background-color: #344a72;
}

.auth-container {
  width: 100%;
}

.auth-container .form-content {
  max-width: 460px;
  background-color: white;
  margin: auto;
  border-radius: 13px;
  padding-bottom: 20px;
  width: 100%;
}

.sign-up {
  /* width: 360px; */

  /* min-height: 420px; */

  /* background-color: aliceblue; */

  /* border-radius: 13px; */

  /* padding: 13px 0; */
}

.auth-container form a {
  font-weight: 400;
  color: #0171d3;
}

.auth-container form a:hover {
  text-decoration: underline;
}

.auth-container .sign-up h1,
.auth-container .login-box h1 {
  padding-top: 15px;
  text-align: center;
}

.sign-up h4 {
  text-align: center;
}

.auth-container form,
.media-option {
  width: 100%;
  padding: 0 20px;
}

.media-option.last-child {
  margin-bottom: 1.6rem;
}

.media-option a {
  display: flex;

  /* background-color: #4267b2; */
}

.auth-container form label {
  display: flex;
  margin-top: 10px;
  font-size: 1rem;
}

.auth-container form input {
  width: 100%;
  padding: 10px 15px;
  border: none;
  border: 1px solid #cacaca;
  border-radius: 6px;
  outline: red;
  font-weight: 400;
}

.auth-container form input:focus {
  border-bottom-width: 2px;
}

.eye-icon {
  color: #8b8b8b;
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 18px;
  cursor: pointer;

  /* padding: 1px; */

  /* transform: translateY(-50%) ; */
}

.line {
  position: relative;
  height: 1px;
  width: 100%;
  background-color: #d4d4d4;
  margin: 36px 0;
}

.line::before {
  content: "Or";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #232836;
  padding: 0 15px;
}

.auth-container input[type="button"] {
  width: 320px;
  height: 35px;
  margin-top: 20px;
  border: none;
  background-color: #49c1a2;
  color: white;
  font-size: 16px;
  font-weight: 400;

  /* border-radius: 8px; */
}

.form-link {
  text-align: center;
  margin-top: 10px;
}

.form-link span,
.form-link span a {
  font-size: 14px;
  font-weight: 400;
  color: #232836;
}

.auth-container p {
  text-align: center;

  /* padding-top: 20px; */
  font-size: 15px;
}

.auth-container form .field {
  width: 100%;
  position: relative;
}

.social-field {
  width: 100%;
  position: relative;
  height: 50px;
  margin-top: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* background-color: #4267b2; */
}

.social-field.facebook {
  color: #fff;
  background-color: #4267b2;

  /* background-color: #4267b2; */
}

a.facebook .facebook-icon {
  height: 28px;
  width: 28px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: #0171d3;
}

.facebook-icon,
.google-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.facebook-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

a.google {
  border: 1px solid #cacaca;
}

a.google span {
  font-weight: 500;
  opacity: 0.6;
  color: #232836;
}

.google-icon {
  height: 20px;
  width: 20px;
}

.auth-container form button {
  width: 100%;
}

.auth-container form button:hover {
  background-color: #016dcb;
}

.para-2 {
  text-align: center;
  color: white;
  font-size: 15px;
  margin-top: -10px;
}

.para-2 a {
  color: #49c1a2;
}
