.progress {
  width: 50px;
  height: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid lightblue;
  border-right-color: var(--color-theme);
  animation: skimmer 1s infinite linear;
}

.progress__container {
  display: flex;
  justify-content: center;
  padding: 3rem;
  width: 100%;
  align-items: center;
  
}

@keyframes skimmer {
  to {
    transform: rotate(1turn);
  }
}
