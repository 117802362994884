.footer-container > div {
  flex: 1;
  padding: 0 1.2rem;
  font-size: 0.7rem;
}

.footer-container > div:nth-last-child() {
  border-right: none;
}

footer p,
footer li,
footer a {
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
}

footer a {
  text-decoration: underline;
}


footer h3 {
  font-size: 1.5rem;
}


@media screen and (max-width: 600px) {
  .footer-container > div {
    text-align: center;
    border-bottom: 1px solid rgb(146, 145, 145);
    margin: 1.5rem auto;
    padding: 1rem 1.2rem;
    width: 100%;
  }

  .footer-container {
    flex-direction: column;
  }
}
