.navbar.sticky-nav{
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  left: 0;
  background-color: rgb(17 24 39 / 0.6);
  padding-bottom: 0.4rem;

}

.navbar.sticky-nav a,.navbar.sticky-nav .menu-icon{
color: #fff;

}

.navbar {
  display: flex;
  justify-content: space-between;
  height: 90px;
  position: relative;
  color: white;
  padding: 0 4rem;
}

.nav-div {
  display: flex;
  align-items: flex-end;
  flex: 5;
  height: 100%;
  padding: 0 2rem;
  color: white;
}

.nav-links {
  display: flex;
  list-style: none;
  color: white;
  z-index: 70;
}

.logo {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  max-width: 230px;
}

nav .logo a {
  position: relative;
  color: #fff;
  bottom: 0;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  margin-right: 1rem;
  display: flex;
  justify-self: end;
}

.logo img {
  width: 100px;
}

.nav-item {
  padding: 0.83rem 1.5rem;
  height: 100%;
  display: flex;
  align-items: flex-start;
  text-wrap: nowrap;
}

.nav-item a {
  text-transform: uppercase;
}

.search-div {
  display: flex;
  margin: auto;
  background-color: white;
  border-radius: 6px;
  padding: 0 0 0 0;
}

.search-div input {
  width: 100%;
  padding: 0.4rem 1rem;
  outline: none;
  border: none;
}

.search-div input:focus {
  outline: none;
  border: none;
}
.search-div input:hover {
  outline: none;
  border: none;
}

.search-div button {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 29px;
  border-radius: 0 6px 6px 0;
}


.search-component form {
}

.link-items {
  position: absolute;
  width: 100%;
  background: linear-gradient(136deg, white, var(--color-theme));
  left: 0;
  top: 100%;
  padding: 2rem;
  line-height: 100%;
  display: none;
  gap: 15px;
  z-index: 33;
}

.nav-item:hover .link-items {
  display: flex;
}


.link-items > * {
  margin: 1px;
  padding: 0 2rem;
  border-right: 1px solid grey;
}

.cart {
  position: relative;
}


.cart span {
  position: absolute;
  top: -50%;
  right: -50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: var(--color-theme-variant);
}

.mobile-menu-div,
.last {
  display: none;
}

.search-list {
  position: absolute;
  background-color: var(--color-theme-variant);
  width: 100%;
  padding: 0rem 0 0 0rem;
  z-index: 51;
}
.search-list a{
  display: block;
  padding: 0.5rem 1rem;
}
.search-list a:hover{
  background-color: #114364;
}

.search-list li {
  padding: 8px 2px;
}

.search-list li:hover {
  padding: 8px 2px;
}

.search-list li a {
  color: white;
}

.navbar a:hover {
  color: var(--color-theme);
  font-weight: 500;
}

.link-items a:hover {
  color: rgb(127, 106, 127);
}

@media screen and (max-width: 1600px) {
  .navbar {
    padding: 0 2.48rem 0 0;
  }

  .nav-div {
    padding: 0;
  }

  .nav-info .nav-item {
    padding: 0.83rem 1.8rem;
  }

  .nav-item {
    padding: 0.83rem 0.8rem;
  }
}

@media screen and (max-width: 1025px) {
  nav {
    position: relative;
  }

  .navbar {
    padding: 0 1.8rem 0 0.4rem;
  }

  .mobile-menu-div {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .nav-links {
    background-color: rgba(20, 56, 80, 0.959);
    position: absolute;
    left: -100%;
    top: 0;
    width: 80%;
    padding: 1rem 0.4rem 2rem;
    display: block;
    min-height: 100vh;
    color: var(--color-info-light);
    transition: all 0.4s ease-in;
  }

  .nav-item {
    border: none;
    border-bottom: 1px solid white;
    padding: 12px 8px;
    display: block;
    height: auto;
  }

  .nav-links.show-menu {
    left: 0;
  }

  .nav-div {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }

  .mobile-menu-div .menu-icon {
    color: var(--color-primary);
    font-size: 29px;
  }

  .mobile-menu-div .menu-icon.close-icon {
    color: var(--color-white);
    font-size: 29px;
  }

  .nav-div ul .nav-item a {
    display: block;
    height: 100%;
    padding: 6px 12px;
  }

  .nav-div ul .link-items {
    position: relative;
    top: 0;
    padding: 0;
    margin: 0;
    left: 0;
    background: #222629;
    gap: 1px;
  }

  .nav-item:hover .link-items {
    display: block;
    background: var(--color-theme-variant);
  }

  .border {
    border: 1px solid rgb(184, 184, 184);
  }

  .nav-div ul .link-items li a {
    color: #fff;
    margin: 0;
  }

  .link-items > * {
    padding: 0 1rem;
    border-right: none;
    margin: 0;
  }

  .nav-div ul .link-items h4 {
    @apply text-light;
    margin: 8px 0;
    padding: 0;
    text-align: left;
  }

  .nav-div ul .link-items div {
    display: none;
  }

  .last {
    margin-top: 2rem;
    border: none;
  }

  .mobile-menu-div,
  .last {
    display: flex;
    align-items: center;
  }

}
